// import fetch from '@/common/request/fetch' 
import { fetch, logos } from './fetch.js'

// 获取制定logo
const getLogo = () => fetch("/logo.json");
const logo = () => logos("logo.json")

export default {
	getLogo,
	logo
}
