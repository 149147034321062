const platforms = {
  //jjy
  "iot.jjyip.com": {
    "902": {
      port: "",
      suffix: "iot.ctp.noLogo",
      cfg: {
        isScan: false   //是否支持扫码(暂未使用)
      }
    }
  },
  //测试环境
  // "192.168.1.107": {
  //   "8080": {
  //     port: "",
  //     suffix: "iot.ctp.noLogo",
  //     cfg: {
  //       isScan: false   //是否支持扫码
  //     }
  //   }
  // },
}

//可扫码登录的端口 注意：''是指从地址中获取的80端口，只是显示为'' 
const scanPorts = ['', '80', '443', '8080', '81']

const getUrl = (_domain, _port) => {
  let newPort = _port
  let suffix = 'iot.ctp'

  //根据域名查询是否有配置
  if (platforms && platforms[_domain] && platforms[_domain][_port]) {
    //是否有配置端口
    if (platforms[_domain][_port].port != void 0) {
      newPort = platforms[_domain][_port].port;
    }
    //是否有配置后缀
    if (platforms[_domain][_port].suffix != void 0) {
      suffix = platforms[_domain][_port].suffix;
    }
  }
  //端口不为空就需要在端口前增加冒号
  // if (newPort != '') {
  //   newPort = ':' + newPort
  // }
  //拼接url
  let url = _domain + ':' + newPort + '/' + suffix

  return url
}
//是否展示扫码登录
const isScanLogin = () => {
  const domain = document.domain
  const port = location.port
  let isScan = true
  // if (platforms && platforms[domain] && platforms[domain][port] && platforms[domain][port].cfg) {
  //   if (platforms[domain][port].cfg.isScan != void 0) {
  //     isScan = platforms[domain][port].cfg.isScan
  //   }
  // }
  //端口不存在就无法扫码
  if (!scanPorts.includes(port)) {
    isScan = false
  }
  return isScan
}

export {
  getUrl,
  isScanLogin,
}
