/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 * baseImgPath: 图片存放地址
 *
 */
let baseUrl = ''
let routerMode = 'history'
let baseImgPath
let routerBase = '/'
console.log("process.env.NODE_ENV=" + process.env.NODE_ENV);
if (process.env.NODE_ENV === 'development') {
	// baseUrl = '192.168.1.107'
	// baseUrl = 'dayawan.jjyip.com:8443'		//正式平台
	baseUrl = 'iot.jjyip.com'
	// baseUrl = 'xy.jjyip.com:81'
	//baseUrl = '//	10.78.10.83'
	baseImgPath = ''
	routerBase = '/'
} else {
	baseUrl = 'iot.jjyip.com' //'//iot.jjyip.com'
	baseImgPath = ''
	routerBase = '/'
}

export {
	baseUrl,
	routerMode,
	baseImgPath,
	routerBase
}
