import Vue from 'vue'
import VueRouter from 'vue-router'
import common from '../common/common'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Login',
	component: () => import('../views/login.vue'),
	//component: () => import('../views/public/test.vue'),
	//component: () => import('../views/public/video_data_1.vue'),
	// component: () => import('../views/public/history_data.vue'),
	meta: {
		title: '登录'
	}
}, {
	path: '/reset',
	name: 'Reset',
	component: () => import('../views/reset.vue'),
	meta: {
		title: '修改密码'
	},
	path: '/main',
	name: 'Main',
	component: () => import('../views/main.vue'),
	redirect: '/public/live_data',
	children: [{
		path: '/public/live_data',
		name: 'LiveData',
		component: () => import('../views/public/live_data.vue'),
		meta: {
			title: '实时数据'
		}
	}, {
		path: '/public_data',
		name: 'PublicData',
		component: () => import('../views/public/mng.vue'),
		children: [{
			path: '/public/history_data1',
			name: 'HistoryData1',
			component: () => import('../views/public/history_data.vue'),
			meta: {
				title: '历史数据'
			},
			query: {
				type: 'sensor'
			}
		}, {
			path: '/public/queryRain',
			name: 'QueryRain',
			component: () => import('../views/public/queryRain'),
			meta: {
				title: '雨量统计'
			}
		}, {
			path: '/public/history_data2',
			name: 'HistoryData2',
			component: () => import('../views/public/history_data.vue'),
			meta: {
				title: '设备状态'
			},
			query: {
				type: 'online'
			}
		}, {
			path: '/public/average_data',
			name: 'AverageData',
			component: () => import('../views/public/average_data.vue'),
			meta: {
				title: '历史均值'
			}
		}, {
			path: '/public/alarm_data',
			name: 'AlarmData',
			component: () => import('../views/public/alarm_data.vue'),
			meta: {
				title: '报警信息'
			}
		}, {
			path: '/public/standard_data',
			name: 'StandardData',
			component: () => import('../views/public/standard_data.vue'),
			meta: {
				title: '超标数据'
			}
		}, {
			path: '/public/state_data',
			name: 'StateData',
			component: () => import('../views/public/state_data.vue'),
			meta: {
				title: '设备状态'
			}
		}]
	}, {
		path: '/public/picture_data',
		name: 'PictureData',
		component: () => import('../views/public/picture_data.vue'),
		meta: {
			title: '图片浏览'
		}
	},
	{
		path: '/public/video_data',
		name: 'VideoData',
		component: () => import('../views/public/video_data.vue'),
		meta: {
			title: '视频监控'
		}
	}, {
		path: '/public/gps_data',
		name: 'GpsData',
		component: () => import('../views/public/gps_data.vue'),
		meta: {
			title: 'GPS地图'
		}
	}, {
		path: '/dayawan/screenMap',
		name: 'ScreenMap',
		component: () => import('../views/dayawan/screenMap.vue'),
		meta: {
			title: '全屏地图'
		}
	},

	// {
	// 	meta: {
	// 		title: '历史查询'
	// 	}
	// },
	{
		path: '/public_mng',
		name: 'PublicMng',
		component: () => import('../views/public/mng.vue'),
		children: [{
			path: '/user/org_mng',
			name: 'OrgMng',
			component: () => import('../views/user/org_mng.vue'),
			meta: {
				title: '组织管理'
			}
		}, {
			path: '/user/user_mng',
			name: 'UserMng',
			component: () => import('../views/user/user_mng.vue'),
			meta: {
				title: '用户管理'
			}
		}, {
			path: '/user/device_mng',
			name: 'DeviceMng',
			component: () => import('../views/user/device_mng.vue'),
			meta: {
				title: '设备管理'
			}
		}, {
			path: '/admin/jcoUpdate',
			name: 'JcoUpdate',
			component: () => import('../views/admin/jcoUpdate.vue'),
			meta: {
				title: 'jco升级'
			}
		}, {
			path: '/admin/remoteLog',
			name: 'RemoteLog',
			component: () => import('../views/admin/remoteLog.vue'),
			meta: {
				title: '设备远程日志'
			}
		}, {
			path: '/admin/platformLog',
			name: 'PlatformLog',
			component: () => import('../views/admin/platformLog.vue'),
			meta: {
				title: '平台日志'
			}
		}, {
			path: '/admin/productDoc',
			name: 'ProductDoc',
			component: () => import('../views/admin/productDoc.vue'),
			meta: {
				title: '产品文档'
			}
		},
		{
			path: '/public/device_vers',
			name: 'DeviceVers',
			component: () => import('../views/public/device_vers.vue'),
			meta: {
				title: '设备资料'
			}
		}, {
			path: '/user/alarm_config',
			name: 'AlarmConfig',
			component: () => import('../views/user/alarm_config.vue'),
			meta: {
				title: '超标配置'
			}
		}, {
			path: '/user/device_config',
			name: 'DeviceConfig',
			component: () => import('../views/user/device_config.vue'),
			meta: {
				title: '设备GPS配置' //普管权限
			}
		}, {
			path: '/admin/device_config',
			name: 'RDeviceConfig',
			component: () => import('../views/admin/device_config.vue'),
			meta: {
				title: '视频配置' //超管权限
			}
		}, {
			path: '/admin/develop_manage',
			name: 'DevelopManage',
			component: () => import('../views/admin/develop_manage.vue'),
			meta: {
				title: '布防管理' //布防管理
			}
		}, {
			path: '/admin/message',
			name: 'Message',
			component: () => import('../views/admin/message.vue'),
			meta: {
				title: '短信' //短信
			}
		}, {
			path: '/admin/link_manage',
			name: 'LinkManage',
			component: () => import('../views/admin/link_manage.vue'),
			meta: {
				title: '联动管理' //联动管理
			}
		}, {
			path: '/dayawan/export_data',
			name: 'dayawan_export_data',
			component: () => import('../views/dayawan/export_data.vue'),
			meta: {
				title: '导出大亚湾水库数据'
			},
			query: {
				type: 'sensor'
			}
		}, {
			path: '/dayawan/export_data',
			name: 'dayawan_export_data',
			component: () => import('../views/dayawan/export_data.vue'),
			meta: {
				title: '导出大亚湾水库数据'
			},
			query: {
				type: 'sensor'
			}
		}, {
			path: '/dayawan/export_dmData',
			name: 'dayawan_export_dmData',
			component: () => import('../views/dayawan/export_dmData.vue'),
			meta: {
				title: '导出大亚湾内涝数据'
			},
			query: {
				type: 'sensor'
			}
		}
		]
	},
	// {
	// 	path: '/second/main_page',
	// 	name: 'MainPage',
	// 	component: () => import('../views/second/main_page.vue'),
	// 	meta: {
	// 		title: '水电生生态流量管理系统'
	// 	}
	// },
	{
		path: '/second/facility_detail',
		name: 'FacilityDetail',
		component: () => import('../views/second/facility_detail.vue'),
		meta: {
			title: '设备详情'
		}
	}, {
		path: '/second/sys',
		name: 'sys',
		component: () => import('../views/second/sys.vue'),
		meta: {
			title: '系统概况'
		}
	}, {
		path: '/second/report',
		name: 'report',
		component: () => import('../views/second/report.vue'),
		meta: {
			title: '统计报表'
		}
	}, {
		path: '/second/picture_datase',
		name: 'picture_datase',
		component: () => import('../views/second/picture_datase.vue'),
		meta: {
			title: '图像监视'
		}
	}]
}, {
	path: '/visualJjy',
	name: 'VisualJjy',
	component: () => import('../views/visual_1.vue'),
	meta: {
		title: '大屏可视化'
	}
}, {
	path: '/visual',
	name: 'Visual',
	component: () => import('../views/visual_2.vue'),
	meta: {
		title: '大屏可视化'
	}
}, {
	path: '/pearVisual',
	name: 'pearVisual',
	component: () => import('../views/yichun/pear.vue'),
	meta: {
		title: '梨园湾泵站', noAuth: true		//	无需通过登录权限验证
	}
},
{
	path: '/longVisual',
	name: 'longVisual',
	component: () => import('../views/yichun/long.vue'),
	meta: {
		title: '龙溪泵站', noAuth: true 	//	无需通过登录权限验证
	}
},
{
	path: '/update',
	name: 'Update',
	component: () => import('../views/updateFireware/index.vue'),
	meta: {
		title: '固件更新', noAuth: true 	//	无需通过登录权限验证
	}
}, {
	path: '/factory',
	name: 'factory',
	component: () => import('../views/admin/factory.vue'),
	meta: {
		title: 'factory'
	}
}, {
	path: '/public/typhoon_path',
	name: 'TyphoonPath',
	component: () => import('../views/public/typhoon_path.vue'),
	meta: {
		title: '台风路径'
	}
}]


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// 更改页面顶部标题
	if (to.meta.title) {
		document.title = to.meta.title;
	}

	// console.log(555, to);

	// 处理空路由
	if (to.name == null) {
		window.location.href = "/";
		next();
		return;
	}
	if (to.name == "Login") {
		// 清除用户数据
		localStorage.clear();
		sessionStorage.clear();
		next();
		return;
	}


	//部分不需要权限验证的路由
	if (to.meta && to.meta.noAuth) {
		next();
		return;
	}


	//全局判断是否登录
	const loginData = common.cGetUser();

	// let wxToken = sessionStorage.getItem("wxToken");
	console.log(loginData);
	if ((loginData.loginMode == "pwd" && loginData.username == "") || (loginData.loginMode == "code" && loginData.wxToken == "")) {
		this.$router.push({
			path: '/'
		})
		next();
		return;
	}
	// 存储路由标签
	let user = sessionStorage.getItem("username");
	let itemKey = user + "_routes";
	let routeTags = localStorage.getItem(itemKey);
	let arr = new Array();
	if (routeTags) {
		arr = JSON.parse(routeTags);
		let isExist = false;
		arr.forEach((item) => {
			if (item.name == to.name) {
				isExist = true;
			}
		})
		if (!isExist) {
			arr.push({
				title: to.meta.title,
				name: to.name,
				path: to.fullPath
			});
		}
	} else {
		arr.push({
			title: to.meta.title,
			name: to.name,
			path: to.fullPath
		});
	}
	localStorage.setItem(itemKey, JSON.stringify(arr));
	next();
})

export default router
