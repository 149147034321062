import {
	baseUrl
} from './env'
import Axios from "axios"
import store from '../../store'
const fetch = async (url = '', data = {}, type = 'GET', method = 'fetch') => {
	type = type.toUpperCase()
	//url = baseUrl+":"+location.port+ url
	url = baseUrl + url
	if (type === 'GET') {
		let dataStr = '' // 数据拼接字符串
		Object.keys(data).forEach(key => {
			dataStr += key + '=' + data[key] + '&'
		})
		if (dataStr !== '') {
			dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'))
			url = url + '?' + dataStr
		}
	}
	//if(url.indexOf("http")==-1 && url.indexOf("https")==-1)
	{
		//url="https://"+url;
	}

	console.log("fetch.url=" + url);

	if (window.fetch && method === 'fetch') {
		let requestConfig = {
			credentials: 'include',
			method: type,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			mode: 'cors',
			cache: 'force-cache'
		}
		if (type === 'POST') {
			// console.log(data);
			Object.defineProperty(requestConfig, 'body', {
				value: Object.keys(data).map(function (key) {
					return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
				}).join("&")

			})
		}
		try {
			// return await fetch(url, requestConfig).then(response => response.json())
			return await fetch(url).then(response => response.json())

		} catch (error) {
			throw new Error(error)
		}
	} else {
		return new Promise((resolve, reject) => {
			let requestObj
			if (window.XMLHttpRequest) {
				requestObj = new XMLHttpRequest()
			} else {
				requestObj = new window.ActiveXObject()
			}
			let sendData = ''
			if (type === 'POST') {
				sendData = JSON.stringify(data)
			}
			requestObj.open(type, url, true)
			requestObj.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
			requestObj.send(sendData)
			requestObj.onreadystatechange = () => {
				if (requestObj.readyState === 4) {
					if (requestObj.status === 200) {
						let obj = requestObj.response
						if (typeof obj !== 'object') {
							obj = JSON.parse(obj)
						}
						resolve(obj)
					} else {
						reject(requestObj)
					}
				}
			}
		})
	}
}

const logos = (url_json) => {
	//区分环境
	const port = location.port
	let url = location.protocol + '//' + document.domain + ":" + port  //访问logo.json路径
	if (port == 8080 || port == 80 || port == 8081 || port == 81) {
		if (process.env.NODE_ENV === 'development') {
			//json url地址，检查是否包含http前缀
			url = baseUrl.indexOf('http') != -1 ? baseUrl : baseUrl.indexOf('192') != -1 ? 'http://' + baseUrl : 'https://' + baseUrl
		}
	}

	let getUrl = url + '/' + url_json
	// url = baseUrl + ":" + location.port + '/' + url
	// console.log(1212, getUrl);
	Axios.get(getUrl)
		.then(({ data }) => {
			data.url = url
			store.commit("settingStore/SET_LOGO", data);
		}).catch((error) => {
			console.log(error);
		});
}
export {
	fetch,
	logos
}