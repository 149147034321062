import Vue from 'vue'
import Vuex from 'vuex'

import settingStore from '@/store/modules/setting'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		profile: {}, //设备种类
		treeData: [], //树列表数据
		curTreeData: [], //选中某一节点数据
		alarmData: [],	//报警数据
		accessToken: '', //海康视频控制云台所需token//at.az4n1vr77fkrrtdo6m32ja9c7eknehl3-2kqhwkvu2u-0ak1c5l-6kwxgvfnr
	},
	getters: {
		// 获取树列表全部设备
		getAllDevices: state => {
			let datas = new Array();
			const pushNode = (nodes) => {
				for (let i = 0; i < nodes.length; i++) {
					if (nodes[i].type == 'device') {
						datas.push(nodes[i]);
					} else {
						if (nodes[i].children) {
							pushNode(nodes[i].children);
						}
					}
				}
			}
			let nodes = state.treeData;
			pushNode(nodes);
			return datas;
		},
		// 获取当前选中节点下的所有设备
		getCurDevices: state => {
			let datas = new Array();
			const pushNode = (nodes) => {
				for (let i = 0; i < nodes.length; i++) {
					if (nodes[i].type == 'device') {
						datas.push(nodes[i]);
					} else {
						if (nodes[i].children) {
							pushNode(nodes[i].children);
						}
					}
				}
			}
			let nodes = state.curTreeData.length == 0 ? state.treeData : state.curTreeData;
			pushNode(nodes);
			return datas;
		},
		// 统计设备数量
		getDeviceCount: state => {
			let count = 0,
				online = 0,
				offline = 0,
				alarm = 0;
			const pushCount = (nodes) => {
				for (let i = 0; i < nodes.length; i++) {
					if (nodes[i].type == 'device') {
						count++;
						nodes[i].online == '1' || nodes[i].online == '2' ? online++ : offline++;
					} else {
						if (nodes[i].children) {
							pushCount(nodes[i].children);
						}
					}
				}
			}
			let nodes = state.curTreeData.length == 0 ? state.treeData : state.curTreeData;
			pushCount(nodes);

			let datas = {
				count: count,
				online: online,
				offline: offline,
				alarm: alarm
			}
			return datas;
		}
	},
	mutations: {
		setProfile(state, data) {
			state.profile = data;
		},
		setTreeData(state, dataArr) {
			state.treeData = dataArr;
		},
		setCurTreeData(state, dataArr) {
			state.curTreeData = dataArr;
		},
		setAlarmData(state, dataArr) {
			state.alarmData = dataArr;
		},
		setAccessToken(state, token) {
			state.accessToken = token;
		}
	},
	actions: {},
	modules: {
		settingStore,
	}
})

// 数组去重
const _filter = (data) => {
	var arr = data.filter((x, index, self) => {
		var arrids = []
		data.forEach((item, i) => {
			arrids.push(item.id)
		})
		return arrids.indexOf(x.id) === index
	})
	return arr;
}
