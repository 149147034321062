/*
XiongWanPing 2020.03.09 仿android Log
用法
const Log = require('utils/log.js')

  Log.v(TAG,"Log.v")
  Log.d(TAG,"Log.d")
  Log.i(TAG,"Log.i")
  Log.w(TAG,"Log.w")
  Log.e(TAG,"Log.e")
  
  要打印对象数据时可采用逗号分隔，比如
  Log.v(TAG,"fireEvent("+eventName+"),json=",jsonOrText)
*/

module.exports = {
  v: logV,
  d: logD,
  i: logI,
  w: logW,
  e: logE,
}

function getDateTime()
{
  var now = new Date();
  var month = ("0" + (now.getMonth() + 1)).slice(-2);
  var day = ("0" + (now.getDate())).slice(-2);
  var date = month + "." + day;
  
  var hour = ("0" + (now.getHours())).slice(-2);
  var minute = ("0" + (now.getMinutes())).slice(-2);
  var second = ("0" + (now.getSeconds())).slice(-2);
  var time = hour + ":" + minute+":"+second;

  return date + " " + time;
}

function logV(tag,...params) {

  console.log(getDateTime()+"#"+tag+" ",params);
}

function logD(tag,...params) {

  console.log(getDateTime()+"#"+tag+" ",params);
}

function logI(tag,...params) {

  console.info(getDateTime()+"#"+tag+" ",params);
}

function logW(tag,...params) {
  console.warn(getDateTime()+"#"+tag+" ",params);
}

function logE(tag,...params) {
  console.error(getDateTime()+"#"+tag+" ",params);
}

