//系统设置
const state = () => ({
  fullscreen: false,// 全屏
  platform: window.location.hostname ? window.location.hostname : '',    //平台
  logo: {},//平台基础配置项
  dbName: "",  //大屏标识
})
const mutations = {
  // 全屏
  SET_FULLSCREEN: (state, fullscreen) => {
    state.fullscreen = fullscreen
  },
  //设置平台 测试用
  SET_PLATFORM: (state, platform) => {
    state.platform = platform
  },
  //设置 平台基础数据
  SET_LOGO: (state, logo) => {
    // console.log(333, logo);
    state.logo = logo
  },
  //设置 平台大屏数据
  SET_dpNAME: (state, dbName) => {
    state.dbName = dbName
  }
}
const actions = {

}
const getters = {
  //从域名中取hostname，是否为自己平台
  getPlatform(state) {
    let platform = state.platform
    if (platform == "localhost" || platform == "iot.jjyip.com") {
      return true
    } else {
      return false
    }
  },
  //获取平台基础数据
  getLogo(state) {
    if (state.loginTitle == undefined || state.loginTitle == "") {
      state.loginTitle = false
    }
    if (state.mainTitle == undefined || state.mainTitle == "") {
      state.mainTitle = false
    }
    if (state.wallTitle == undefined || state.wallTitle == "") {
      state.wallTitle = false
    }
    return state.logo
  },
}

export default {
  namespaced: true, //这个可以让在组件中直接通过mapState引用子仓库
  state,
  getters,
  actions,
  mutations
};