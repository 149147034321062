<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      treeDatas: [],
      devicesDone: false,
    }
  },
  computed: {
    deviceArr() {
      return this.$store.getters.getAllDevices;
    }
  },
  watch: {
    "$store.getters.getCurDevices": {
      handler: function (newVal) {
        // 设置热点设备
        this.setHotDevices(newVal);
      }
    },
  },
  methods: {
    setHotDevices(chooseDevice) {
      let uids = "";
      chooseDevice.forEach((item) => {
        uids += item.id + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json);
    }
  },
  created() {
    // 设置主题颜色
    // let color = this.common.cGetThemeColor();
    // if (color != "") {
    // 	this.common.cSetThemeColor(color);
    // }
    // websocket连接
    this.ws.connect();
  },
  mounted() {
    // 登录result
    this.ws.addCallback('userLoginAck', (res) => {
      this.devicesDone = false;
      //console.log("skip login action#2");return;
      if (this.common.cGetResult(res)) {
        // 获取设备iotProfile
        this.ws.send({
          "cmd": "iotProfile"
        });
        // 获取视频accessToken
        this.ws.send({
          "cmd": "hikToken"
        })
        //获取大屏信息
        this.ws.send({
          "cmd": "userDataStore",
          "id": "config", /* 唯一标记，比如设备uid或其他字样均可 */
          "tag": "visualConfig",/* 自定义标记*/
        })

      } else {
        console.log("push /");
        this.$router.push({ path: '/' })
      }
    })
    this.ws.addCallback('iotProfileAck', (res) => {
      this.$store.commit('setProfile', res);
      if (this.$store.state.treeData.length > 0) {
        return;
      }
      let json = {
        "cmd": "regions"
      }
      this.ws.send(json);
    })
    this.ws.addCallback('hikTokenAck', (res) => {
      if (this.common.cGetResult(res)) {
        this.$store.commit('setAccessToken', res.token);
      }
    })
    this.ws.addCallback('hikTokenReady', (res) => {
      //token快过期时平台会主动发送的命令
      if (this.common.cGetResult(res)) {
        this.$store.commit('setAccessToken', res.token);
      }
    })
    this.ws.addCallback('userDataStoreAck', (res) => {
      //获取大屏内部字段
      if (this.common.cGetResult(res) && res.tag == "visualConfig") {
        this.$store.commit("settingStore/SET_dpNAME", res.items.length ? res.items[0].data.dbName : '');
      }
    })


    // 获取设备列表
    // this.treeDatas = [{
    //   id: '0',
    //   title: '全部设备',
    //   type: 'org'
    // }];

    this.ws.addCallback('regionsAck', (res) => {
      // 获取设备列表
      this.treeDatas = [{
        id: '0',
        title: '全部设备',
        type: 'org'
      }];

      let regions = res.regions;
      if (regions.length > 0) {
        regions.filter((item) => {
          item.id = item.regionId;
          item.parentId = item.parentRegionId;
          item.type = "org";
        })
      }
      this.treeDatas = this.treeDatas.concat(regions);
      // 获取设备数据
      let json = {
        "cmd": "devices"
      }
      this.ws.send(json);
    })

    this.ws.addCallback('devicesAck', (res) => {

      if (this.devicesDone) {
        return;
      }
      let devices = res.devices;
      if (devices.length > 0) {
        devices.filter((item) => {
          item.id = item.uid;
          item.parentId = item.regionId;
          item.type = "device";
          // 字段不初始化页面不更新
          item.alarm = 0;//是否报警
          item.datas = [];
          item.videoConfig = {};
          item.gpsConfig = {};
        })
      }
      this.treeDatas = this.treeDatas.concat(devices);
      let datas = this.common.cTransTree(this.treeDatas);
      //此处JSON.parse(JSON.stringify(datas))解决state数据改变页面不更新的问题
      this.$store.commit('setTreeData', JSON.parse(JSON.stringify(datas)));
      this.devicesDone = true;
      // console.log(this.dataArr);


      //获取报警设备
      let json = {
        "cmd": "alarmings"
      }
      this.ws.send(json);
    })

    // 监测在线离线状态
    this.ws.addCallback('deviceOnline', (res) => {
      let uidData = this.common.cFindObj(this.deviceArr, "id", res.uid);
      if (uidData != null) {
        uidData.online = '1';
      }
    })
    this.ws.addCallback('deviceOffline', (res) => {
      let uidData = this.common.cFindObj(this.deviceArr, "id", res.uid);
      if (uidData != null) {
        uidData.online = '0';
        uidData.alarm = 0;//设备离线关闭当前设备报警
      }
    })

    //全部报警数据
    this.ws.addCallback('alarmingsAck', (res) => {
      //循环返回报警数据，给数据增加设备名称
      let alarmList = res.devices
      let profile = this.$store.state.profile;
      //给报警数据增加设备名称
      alarmList.length > 0 && alarmList.forEach(item => {
        let uidData = this.common.cFindObj(this.deviceArr, "id", item.uid)
        item.title = uidData.title
        let fields = profile.profile.models[uidData.model];//取model 中 fields
        //给报警传感器增加名称
        item.items.forEach(e => {
          let value = fields.fields[e.tag].title
          e.title = value
        })
        // console.log(999, alarmList, fields);
        //左侧数据列表变红
        uidData.alarm = 1

      })
      //报警列表存入store
      this.$store.commit('setAlarmData', JSON.parse(JSON.stringify(alarmList)));
    })

    this.ws.addCallback('alarmChanged', (res) => {

      let profile = this.$store.state.profile;
      let alarmList = this.$store.state.alarmData;
      let uidData = this.common.cFindObj(this.deviceArr, "id", res.uid);

      //设备开始报警
      if (res.alarming == "1") {
        let alarmData = alarmList.filter(item => { return item.uid == res.uid })
        let fields = profile.profile.models[uidData.model];//取model 中 fields
        let items = JSON.parse(res.items)
        //列表中没有就添加
        if (alarmData.length == 0) {
          //给传感器增加名称
          items.forEach(e => {
            let value = fields.fields[e.tag].title
            e.title = value
          })
          let alarmItem = {
            items: items,
            uid: res.uid,
            title: uidData.title,
            time: res.time
          }
          //添加到全局报警列表
          alarmList.push(alarmItem)
          //左侧设备树变红
          uidData.alarm = 1
        } else {    //列表中有更新数据
          items.forEach(e => {
            let value = fields.fields[e.tag].title
            e.title = value
          })
          alarmList.forEach(e => {
            if (e.uid == res.uid) {
              e.items = items //更新报警传感器数据
              e.time = res.time//更新报警时间
            }
          })
        }
      } else {
        //筛选没有取消报警的设备
        let alarmItems = alarmList.filter(e => { return e.uid != res.uid })
        uidData.alarm = 0
        //未取消的报警的数据重新写入到全局报警列表
        this.$store.commit('setAlarmData', JSON.parse(JSON.stringify(alarmItems)));
      }

      //从设备报警列表里面 操作报警设备
      //给树形列表中字段 alarm = 0

      // console.log(555, res);
    })


    // 监测设备数据变化
    this.ws.addCallback('event', (res) => {
      if (res.type != "sensor") {
        return;
      }
      let uidData = this.common.cFindObj(this.deviceArr, "id", res.uid);
      if (uidData != null) {
        let data = this.parseProfile.parseData(uidData.model, res.data ? res.data : "");
        uidData.lastAlive = this.common.cGetDate('-') + " " + this.common.cGetTime(':');
        uidData.datas = JSON.parse(JSON.stringify(data));
      }
    })

    // 账号删除/禁用/密码修改result
    const userEvent = (msg) => {
      this.$alert(msg, '提示', {
        confirmButtonText: '确定',
        callback: action => {
          this.$router.push({
            path: '/'
          })
        }
      });
    }
    this.ws.addCallback('userRemoved', (res) => {
      userEvent("该账号已被删除");
    })
    this.ws.addCallback('userDisabled', (res) => {
      userEvent("该账号已被禁用");
    })
    this.ws.addCallback('logout', (res) => {
      if (res.desc == "PasswordChanged") {
        userEvent("该账号密码已被修改");
      } else {
        userEvent("请重新登录");
      }
    })
    //获取平台配置
    this.req.logo()
    // 测试数据
    // let testTreeData = this.common.cGetTreeData();
    // this.$store.commit('setTreeData', testTreeData);
  }
}
</script>

<style lang="scss">
body {
  margin: 0 auto;
  color: $color_main;
  font-size: $size_main;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
