import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import Tree from "vue-giant-tree"
import BaiduMap from 'vue-baidu-map'
import * as echarts from 'echarts'
import '@/views/second/directive/directive.js'

import './common/element-variables.scss'
import common from './common/common.js'
import log from './common/log.js'
import parseProfile from './common/parseProfile.js'
import req from './common/request/requests.js'
import ws from './common/websocket.js'
// import Axios from "axios";
// import VueHighlightJS from 'vue-highlightjs'
// import 'highlight.js/styles/default.css'
// import 'highlight.js/styles/atom-one-dark.css'
import CompositionApi from '@vue/composition-api'
import scroll from 'vue-seamless-scroll'  //无缝滚动插件
import { message } from './common/resetMessage'
import "vue-virtual-scroller/dist/vue-virtual-scroller.css" // 引入它的 css
import VueVirtualScroller from "vue-virtual-scroller" // 引入它

Vue.config.productionTip = false
Vue.prototype.common = common
Vue.prototype.log = log
Vue.prototype.parseProfile = parseProfile
Vue.prototype.req = req
Vue.prototype.ws = ws
Vue.prototype.echarts = echarts
// Vue.prototype.Axios = Axios
Vue.prototype.$message = message;


Vue.use(scroll)
Vue.use(CompositionApi)
Vue.use(ElementUI)
// Vue.use(VueHighlightJS);
Vue.use(BaiduMap, {
	// ak: 'ICSLlCvGgDNpNF7btAAyjFXMeZIehgQp'
	ak: 'ocw2fdWmfVG9N85nOACUvcH5GEKTWdLM'
})
Vue.use(VueVirtualScroller) // use 它

new Vue({
	router,
	store,
	// Axios,
	render: function (h) {
		return h(App)
	}
}).$mount('#app')
