// 项目名称
const appCustomer = 'jjy'
// 根据浏览器内核判断应该采用实时音视频视频格式
const videoType = () => {
	if (navigator.userAgent.indexOf("chrome") == -1) {
		// 是chrome浏览器 
		return 'hls'
	} else {
		// 不是谷歌浏览器
		return 'rtmp'
	}
}

/**
 * 功能权限开放
 * joker
 * @param {*} name 				功能名字
 * @param {*} appCustomer 		项目公司【如：信宜】
 * @returns 
 */
const featureEnabled = (name) => {
	if (appCustomer == "xinYi") {
		// deviceSnap [抓拍] [工程图库] [定制菜单]
		if (name == "deviceSnap" || name == 'gctk' || name == 'customizedMenus' || name == 'huaweiVideo') return true;
	}
	return false;
}

// 生成随机数
const cRandom = () => {
	return Math.floor(Math.random() * 100);
}
const cRandom1 = () => {
	return Math.floor(Math.random() * 3 + 1);
}

// 随机生成一个四个字母的字符串
const cRandomCode = () => {
	let arr = new Array();
	let str = "";
	for (let i = 65; i < 91; i++) {
		arr.push(String.fromCharCode(i));
	}
	for (let i = 0; i < 4; i++) {
		let pos = Math.round(Math.random() * (arr.length - 1));
		str += arr[pos];
	}
	return str;
}

// 模拟数据
const cGetTreeData = () => {
	let level1Data = new Array();
	let level1Count = 2;
	for (let i = 0; i < level1Count; i++) {
		level1Data[i] = {
			id: 'level1' + cRandomCode(),
			title: '金圣涛工业园-' + (i + 1) + '栋',
			time: '',
			type: 'org'
		};
		let level2Data = new Array();
		let level2Count = Math.floor(Math.random() * 10);
		let models = ['lift', 'water', 'power'];
		for (let j = 0; j < level2Count; j++) {
			level2Data[j] = {
				id: 'level2' + cRandomCode(),
				title: '北--' + (j + 1) + '栋',
				time: '',
				type: 'org'
			};
			let level3Data = new Array();
			let level3Count = Math.floor(Math.random() * 10);
			for (let k = 0; k < level3Count; k++) {
				level3Data[k] = {
					id: 'uid' + cRandomCode(),
					title: '设备00' + k + cRandomCode(),
					state: Math.floor(Math.random() * 2),
					time: '2020-08-15 09:33:00',
					model: models[Math.floor(Math.random() * 3)],
					type: 'device'
				};
			}
			level2Data[j]['children'] = level3Data;
		}
		level1Data[i]['children'] = level2Data;
	}
	let datas = [{
		id: '0',
		title: '全部设备',
		children: level1Data,
		type: 'org'
	}];
	let models = ['lift', 'water', 'power'];
	let datas2 = [{
		id: '0',
		title: '全部设备',
		children: [{
			id: '00000EMU', //节点id
			title: '测试设备test', //节点名称
			state: 1, //设备状态
			time: '2020-08-15 09:33:00',
			model: models[Math.floor(Math.random() * 3)],
			type: 'device' //节点类型
		}, {
			id: '00001HQK',
			title: '',
			state: 2,
			time: '2020-08-15 09:33:00',
			model: models[Math.floor(Math.random() * 3)],
			type: 'device'
		}],
		type: 'org'
	}]
	return datas;
}
const cGetSensorData = () => {
	let dataArr = new Array();
	// let dataIds = ['pm25', 'pm10', 'tsp', 'tem', 'hum', 'vol', 'bp', 'hf'];
	// let dataNames = ['PM2.5', 'PM10', 'TSP', '温度', '湿度', '声音', '大气压', '氟化氢'];
	// let dataUnits = ['ug/m³', 'ug/m³', 'ug/m³', '℃', '%', 'dB', 'kPa', 'ppm'];
	let dataIds = ['water', 'wind', 'water2'];
	let dataNames = ['水位', '风速', '雨量'];
	let dataUnits = ['米', '米/秒', '毫米/分钟'];
	for (let j = 0; j < dataNames.length; j++) {
		dataArr.push({
			id: dataIds[j],
			title: dataNames[j],
			unit: dataUnits[j],
			isShow: true
		})
	}
	return dataArr;
}
const cGetDeviceData = () => {
	let dataArr = new Array();
	for (let i = 0; i < 15; i++) {
		// 传感器数据
		let sensorDatas = new Array();
		// let dataIds = ['pm25', 'tsp', 'vol', 'hf', 'hum', 'pm10', 'bp', 'tem'];
		let dataIds = ['water', 'wind', 'water2', 'hf', 'hum', 'pm10', 'bp', 'tem'];
		for (let j = 0; j < Math.floor(Math.random() * 4 + 5); j++) {
			sensorDatas.push({
				id: dataIds[j],
				value: cRandom()
			})
		}
		// 合并去重
		let sensorArr = cGetSensorData();
		for (let j = 0; j < sensorArr.length; j++) {
			for (let item of sensorDatas) {
				if (item.id == sensorArr[j].id) {
					sensorArr[j].value = item.value;
					break;
				}
			}
		}
		// 数据
		dataArr.push({
			name: '设备' + '00' + i + cRandomCode(),
			uid: '00' + i + cRandomCode(),
			state: cRandom1() + '',
			dateTime: '2021.03.10 12:00:59',
			datas: sensorArr
		})
	}
	return dataArr;
}





// 主题颜色
const cGetThemeColor = () => {
	let color = localStorage.getItem("themeColor");
	if (!cIsNull(color)) {
		return color;
	}
	return "";
}
const cSetThemeColor = (color) => {
	localStorage.setItem("themeColor", color);
	document.getElementsByTagName('body')[0].style.setProperty('--theme', color);
}

// 非空判断
const cIsNull = (data) => {
	if (data == null || data == undefined || data == "") {
		return true;
	}
	return false;
}

// 地图ak
const cMapAk = () => {
	// const ak = "ocw2fdWmfVG9N85nOACUvcH5GEKTWdLM&services=&t=20190123111209";
	// const ak = "ICSLlCvGgDNpNF7btAAyjFXMeZIehgQp";
	const ak = "ocw2fdWmfVG9N85nOACUvcH5GEKTWdLM";

	return ak;
}

// 颜色
const cColors = () => {
	// const color = '#' + (Math.random() * 0xffffff << 0).toString(16);//随机生成一个颜色
	const colors = ['#409EFF', '#A980F1', '#20cdd6', '#2072de', '#fc7937', '#4DAA70', '#1F8CC4', '#00aa7f',
		'#de5581', '#1dd7fc', '#409EFF', '#A980F1', '#20cdd6', '#2072de', '#fc7937', '#4DAA70', '#1F8CC4',
		'#00aa7f', '#de5581', '#1dd7fc', '#409EFF', '#A980F1', '#20cdd6', '#2072de', '#fc7937', '#4DAA70',
		'#1F8CC4', '#00aa7f', '#de5581', '#1dd7fc'
	];
	return colors;
}

// 全屏模式
const cFullScreen = () => {
	if (document.fullscreenElement ||
		document.mozFullScreenElement ||
		document.webkitFullscreenElement ||
		document.msFullscreenElement) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}
	} else {
		if (document.documentElement.requestFullscreen) {
			document.documentElement.requestFullscreen();
		} else if (document.documentElement.mozRequestFullScreen) {
			document.documentElement.mozRequestFullScreen();
		} else if (document.documentElement.webkitRequestFullscreen) {
			document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
		} else if (document.body.msRequestFullscreen) {
			document.body.msRequestFullscreen();
		}
	}
}

/**
 * 在数组中找到指定对象
 * arr: 数组
 * field: 字段
 * value: 数值
 */
const cFindObj = (arr, field, value) => {
	let obj = arr.find(item => item[field] == value);
	if (obj && obj != undefined) {
		return obj
	}
	return null;
}

// toast
const cToast = (_this, _msg, _type) => {
	let msg = _msg == '' || _msg == undefined ? '提示' : _msg;
	let type = _type == '' || _type == undefined ? 'error' : _type;
	_this.$message({
		message: msg,
		type: type
	});
}

// 日期
const cGetDate = (sep, date) => {
	if (date == null || date == "" || date == undefined) {
		date = new Date();
	}
	let y = date.getFullYear();
	let m = ('0' + (date.getMonth() + 1)).slice(-2);
	let d = ('0' + date.getDate()).slice(-2);
	if (sep == null || sep == undefined) {
		sep = "";
	}
	return `${y}${sep}${m}${sep}${d}`;
}
// 时间
const cGetTime = (sep) => {
	let date = new Date();
	let h = ('0' + date.getHours()).slice(-2);
	let m = ('0' + date.getMinutes()).slice(-2);
	let s = ('0' + date.getSeconds()).slice(-2);
	if (sep == null || sep == undefined) {
		sep = "";
	}
	return `${h}${sep}${m}${sep}${s}`;
}
// 计算时间
const cComputationTime = (time, sep) => {
	if (time == null || time == undefined) {
		return "";
	}
	time = parseInt(time);
	let h = ('0' + parseInt(time / 10000)).slice(-2);
	let m = ('0' + parseInt(time % 10000 / 100)).slice(-2);
	let s = ('0' + parseInt(time % 100)).slice(-2);
	if (sep == null || sep == undefined) {
		sep = ":";
	}
	return `${h}${sep}${m}${sep}${s}`;
}
// 获取时间范围内的所有日期
const cGetDiffDate = (stime, etime) => {
	stime = stime.substring(0, 4) + "-" + stime.substring(4, 6) + "-" + stime.substring(6);
	etime = etime.substring(0, 4) + "-" + etime.substring(4, 6) + "-" + etime.substring(6);
	//初始化日期列表，数组
	var diffdate = new Array();
	var i = 0;
	//开始日期小于等于结束日期,并循环
	while (stime <= etime) {
		diffdate[i] = stime;

		//获取开始日期时间戳
		var stime_ts = new Date(stime).getTime();
		// console.log('当前日期：' + stime + '当前时间戳：' + stime_ts);

		//增加一天时间戳后的日期
		var next_date = stime_ts + (24 * 60 * 60 * 1000);

		//拼接年月日，这里的月份会返回（0-11），所以要+1
		var next_dates_y = new Date(next_date).getFullYear() + '-';
		var next_dates_m = (new Date(next_date).getMonth() + 1 < 10) ? '0' + (new Date(next_date).getMonth() + 1) +
			'-' : (new Date(next_date).getMonth() + 1) + '-';
		var next_dates_d = (new Date(next_date).getDate() < 10) ? '0' + new Date(next_date).getDate() : new Date(
			next_date).getDate();

		stime = next_dates_y + next_dates_m + next_dates_d;

		//增加数组key
		i++;
	}
	return diffdate;
}

// 数组转树结构
const cTransTree = (data) => {
	let treeData = [];
	if (!Array.isArray(data)) return treeData;

	data.forEach(item => {
		delete item.children; //删除item下的children，以防多次调用
	});

	let map = {};
	data.forEach(item => {
		map[item.id] = item;
	});

	data.forEach(item => {
		let parent = map[item.parentId]; //判断item的pid是否是否存在map中
		if (parent) { //如果存在则表示item不是最顶层的数据
			(parent.children || (parent.children = [])).push(item)
		} else {
			treeData.push(item) // 如果不存在 则是顶层数据
		}
	});
	return treeData;


	// let result = []
	// let map = {}
	// if (!Array.isArray(data)) { //验证data是不是数组类型
	// 	return []
	// }
	// data.forEach(item => { //建立每个数组元素id和该对象的关系
	// 	map[item.id] = item //这里可以理解为浅拷贝，共享引用
	// })
	// data.forEach(item => {
	// 	let parent = map[item.parentId] //找到data中每一项item的爸爸
	// 	if (parent) { //说明元素有爸爸，把元素放在爸爸的children下面
	// 		(parent.children || (parent.children = [])).push(item)
	// 	} else { //说明元素没有爸爸，是根节点，把节点push到最终结果中
	// 		result.push(item) //item是对象的引用
	// 	}
	// })
	// return result //数组里的对象和data是共享的
}

// 请求成功失败判断
const cGetResult = (res) => {
	if (res.error == 0) {
		return true;
	}
	return false;
}
const cResultOk = (_this, _txt) => {
	let txt = _txt == "" || _txt == undefined ? "操作成功" : _txt;
	cToast(_this, txt, "success");
}
const cResultNo = (_this, _txt) => {
	let txt = _txt == "" || _txt == undefined ? "操作失败" : _txt;
	cToast(_this, txt, "error");
}

// 存储背景logo标题等
const cGetLogo = () => {
	let obj = sessionStorage.getItem("logo");
	if (obj) {
		return JSON.parse(obj);
	}
	return "";
}
const cSetLogo = (obj) => {
	sessionStorage.setItem("logo", JSON.stringify(obj));
}

// 存储账号密码
const cGetUser = () => {
	let loginMode = sessionStorage.getItem("loginMode"); //登录模式：code扫码登录，pwd密码登录
	let username = sessionStorage.getItem("username");
	let password = sessionStorage.getItem("password");
	let isAdmin = sessionStorage.getItem("isAdmin");
	let isManager = sessionStorage.getItem("isManager");
	let nickname = sessionStorage.getItem("nickname");
	let wxToken = sessionStorage.getItem("wxToken");
	if (!loginMode) {
		loginMode = "pwd";
	}
	if (!username) {
		username = "";
	}
	if (!password) {
		username = "";
	}
	if (!isAdmin) {
		isAdmin = 0;
	}
	if (!isManager) {
		isManager = 0;
	}
	if (!nickname) {
		isManager = 0;
	}
	if (!wxToken) {
		wxToken = "";
	}
	let obj = {
		loginMode: loginMode,
		username: username,
		password: password,
		isAdmin: isAdmin,
		isManager: isManager,
		nickname: nickname,
		wxToken: wxToken
	}
	return obj;
}
const cSetUser = (loginMode, username, password, isAdmin, isManager, nickname, wxToken) => {
	sessionStorage.setItem("loginMode", loginMode);
	sessionStorage.setItem("username", username);
	sessionStorage.setItem("password", password);
	sessionStorage.setItem("isAdmin", isAdmin);
	sessionStorage.setItem("isManager", isManager);
	sessionStorage.setItem("nickname", nickname);
	sessionStorage.setItem("wxToken", wxToken || '');
}

// 是否为管理员
const cIsManager = () => {
	let user = cGetUser()
	if (user.isAdmin == 1 || user.isAdmin == '1' || user.isManager == 1 || user.isManager == '1') {
		return true
	}
	return false
}

// 效验gps坐标格式 gps格式：lng,lat
const cVerifyGps = (gps) => {
	if (gps.indexOf(",") == -1) {
		return false;
	}
	let gpsArr = gps.split(",");
	let longitude = gpsArr[0];
	let latitude = gpsArr[1];
	//经度，整数部分为0-180小数部分为0到6位
	var longreg =
		/^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/;
	if (!longreg.test(longitude)) {
		// alert('经度整数部分为0-180,小数部分为0到6位!');
		return false;
	}
	//纬度,整数部分为0-90小数部分为0到6位
	var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/;
	if (!latreg.test(latitude)) {
		// alert('纬度整数部分为0-90,小数部分为0到6位!');
		return false;
	}
	return true;
}

// 字节转换
const cByteConversion = (byte) => {
	var size = "";
	if (byte < 1024) { //小于1KB，则转化成B
		size = byte.toFixed(1) + "B"
	} else if (byte < 1024 * 1024) { //小于1MB，则转化成KB
		size = (byte / 1024).toFixed(1) + "KB"
	} else if (byte < 1024 * 1024 * 1024) { //小于1GB，则转化成MB
		size = (byte / (1024 * 1024)).toFixed(1) + "MB"
	} else { //其他转化成GB
		size = (byte / (1024 * 1024 * 1024)).toFixed(1) + "GB"
	}
	var sizeStr = size + ""; //转成字符串
	var index = sizeStr.indexOf("."); //获取小数点处的索引
	var dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
	if (dou == "00") { //判断后两位是否为00，如果是则删除00                
		return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
	}
	return size;
}

// 获取当前日期
const getCurrentDate = () => {
	let today = new Date();
	let year = today.getFullYear();
	let month = ("0" + (today.getMonth() + 1)).slice(-2);
	let day = ("0" + (today.getDate())).slice(-2);
	let date = year + "-" + month + "-" + day;
	return date;
}

//密码校验(8-16位，同时包括数字，大小写自字母，特殊字符)
const cIsPassword = (str) => {
	if (/^[\x00-\xff]+$/.test(str) == false) {
		return false;
	}
	let len = str.length;
	if (len > 16 || len < 8) {
		return false;
	}
	let num = 0; //数字
	let lowerCase = 0; //小写字母
	let upperCase = 0; //大写字母
	let special = 0;   //特殊字符

	for (let i = 0; i < str.length; i++) {
		let c = str.charCodeAt(i);
		if (c >= 48 && c <= 57) {
			//数字
			num++;
		} else if (c >= 65 && c <= 90) {
			//大写字母
			upperCase++;
		} else if ((c >= 97 && c <= 122)) {
			//小写字母
			lowerCase++;
		} else {
			special++;
		}

	}
	//目前只需要 数字、字母、特殊字符中两种组合即可
	let isnum = num > 0 ? 1 : 0
	let isletter = (lowerCase + upperCase) > 0 ? 1 : 0
	let isspecial = special > 0 ? 1 : 0
	if ((isnum + isletter + isspecial) < 2)
		return false;
	return true;
}

//items格式:
//fields1=value1,fields2=value2...
const parseFields = (items) => {
	var ack = new Array();
	var arr = items.split(",");
	for (var i = 0; i < arr.length; i++) {
		let line = arr[i];
		if (line == "") {
			continue;
		}

		let name = "";
		let value = "";
		let pos = line.indexOf("=");
		if (pos != -1) {
			name = line.substring(0, pos);
			value = line.substring(pos + 1);
		} else {
			name = line
		}

		var item = {
			"name": name,
			"value": value
		};
		ack.push(item);
	}

	return ack;
}

const text2Json = (fields) => {
	if (fields == undefined || fields == "") {
		let obj = JSON.parse("{}");
		return obj;
	}

	let items = parseFields(fields)
	let text = "{";
	for (let i = 0; i < items.length; i++) {
		let item = items[i];
		let name = item.name;
		let value = item.value;

		if (i > 0) {
			text += ","
		}

		text += "\"" + name + "\":\"" + value + "\""
	}

	text += "}";

	//Log.v(TAG,"text2Json="+text)

	let obj = JSON.parse(text);
	return obj;
}

export default {
	appCustomer,
	cRandom,
	cRandom1,
	cRandomCode,
	cGetTreeData,
	cGetSensorData,
	cGetDeviceData,
	getCurrentDate,
	featureEnabled,
	videoType,

	cGetThemeColor,
	cSetThemeColor,
	cIsNull,
	cMapAk,
	cColors,
	cFullScreen,
	cFindObj,
	cToast,
	cGetDate,
	cGetTime,
	cComputationTime,
	cGetDiffDate,
	cTransTree,
	cGetResult,
	cResultOk,
	cResultNo,
	cGetLogo,
	cSetLogo,
	cGetUser,
	cSetUser,
	cIsManager,
	cVerifyGps,
	cByteConversion,
	cIsPassword,
	text2Json
}
