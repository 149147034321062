import pStore from '../store/index.js'

//data格式：{n:[{key:value,key:value},{key:value,key:value}],key:'value'}
const parseData2 = (model, data) => {
	data = JSON.parse(data);
	// console.log(222, data);
	let dataArr = new Array();
	let dataStr = new Array();
	Object.keys(data).forEach(key => {
		if (!Array.isArray(data[key])) {
			let item = parseField2(model, { [key]: data[key] })
			dataStr.push(item)
		}

	})
	Object.keys(data).forEach(key => {

		if (Array.isArray(data[key])) {	//判断是否为节点数据
			data[key].forEach((element, index) => {
				element.n = index;
				let itemArr = new Array();
				for (let item in element) {
					let obj = parseField2(model, { [item]: element[item] });
					if (JSON.stringify(obj) != "{}") {
						obj.n = 1;
						itemArr.push(obj);

					}
				}
				dataArr.push(itemArr);
			});
		}
		// else {	//临时加入的方法，显示雨量
		// 	let item = parseField2(model, { [key]: data[key] })
		// 	console.log(111, dataArr, item);
		// 	if (dataArr.length == 0) {
		// 		dataArr[0] = []
		// 	}
		// 	if (item != void 0) {
		// 		dataArr[0].push(item)

		// 	}
		// }
	})
	if (dataArr[0]) {
		dataArr[0].push(...dataStr)
	}

	return dataArr
}

//data格式：n:[{key:value,key:value},{key:value,key:value}]
const parseField2 = (model, data) => {
	if (model == undefined || model == "") {
		return;
	}
	let obj = {};
	let profile = pStore.state.profile;
	let fields = profile.profile.models[model].fields;
	if (!fields) {
		return;
	}
	let valueMaps = profile.profile.models[model].valueMaps;
	for (let item in fields) {
		if (data == undefined || data == "") {
			continue;
		}
		let key = Object.keys(data)[0];
		let val = Object.values(data)[0];

		let itemData = fields[item];
		if (key == item) {
			let curValue = val;
			if (itemData.valueMap && itemData.valueMap != undefined) {
				curValue = parseValue(valueMaps, itemData.valueMap, curValue);
			}
			obj = {
				key: item,
				name: itemData.title,
				rawValue: val, //原始数据
				value: curValue, //显示数据
				unit: itemData.unit,
				hide: itemData.hide
			}
		}
	}
	return obj;
}

// data格式：key1=value,key2=value2...
const parseData = (model, data) => {
	let dataArr = new Array();
	let arr = data.split(",");
	for (let item of arr) {
		let obj = parseField(model, item);
		if (JSON.stringify(obj) != "{}") {
			dataArr.push(obj);
		}
	}
	return dataArr;
}

// data格式：key1=value1
const parseField = (model, data) => {
	// 测试数据，要删除--start
	// model = "lift";
	// 测试数据，要删除--end
	if (model == undefined || model == "") {
		return;
	}
	let obj = {};
	let profile = pStore.state.profile;
	let fields = profile.profile.models[model].fields;
	if (!fields) {
		return;
	}
	let valueMaps = profile.profile.models[model].valueMaps;
	for (let item in fields) {
		if (data == undefined || data == "") {
			continue;
		}
		let pos = data.indexOf("=");
		let key = "";
		let val = "";
		if (pos != -1) {
			key = data.substring(0, pos);
			val = data.substring(pos + 1);
		}
		let itemData = fields[item];
		if (key == item) {
			let curValue = val;
			if (itemData.valueMap && itemData.valueMap != undefined) {
				curValue = parseValue(valueMaps, itemData.valueMap, curValue);
			}
			obj = {
				key: item,
				name: itemData.title,
				rawValue: val, //原始数据
				value: curValue, //显示数据
				unit: itemData.unit
			}
		}
	}
	return obj;
}

const parseValue = (valueMaps, field, value) => {
	let newValue = "";
	for (let item in valueMaps) {
		if (item == field) {
			for (let item2 in valueMaps[field]) {
				if (value == item2) {
					newValue = valueMaps[field][item2];
					break;
				}
			}
		}
	}
	return newValue;
}

//获取字符串中指定字符 "key1=val1,key2=val2"
const getParamsData = (str, key) => {
	if (str == null || str == "" || str == undefined) {
		return "";
	}
	var dataStr = str;
	var start = dataStr;
	if (start != -1) {
		var param = dataStr.slice(start + 1);
		var arrParam = param.split(",");
		for (var i = 0; i < arrParam.length; i++) {
			var pos = arrParam[i].indexOf(key + "=");
			if (pos == 0) {
				pos += key.length;
				var value = arrParam[i].slice(pos + 1);
				return value;
			}
		}
	}
	return "";
}

//取model中联防数据
const alarmSensors = (model) => {
	// console.log("alarmSensors,model=" + model);
	let ack = [];
	let profile = pStore.state.profile;
	//获取联防管理中可设置的参数
	if (model in profile.profile.models) {
		let modelItem = profile.profile.models[model];
		if (modelItem && modelItem.linkage) {
			if ("sensors" in modelItem.linkage) {
				let sensors = modelItem.linkage["sensors"];
				for (let key in sensors) {
					if (key != 'comment') {
						ack.push(
							{
								title: "",
								sensors: key,
								unit: ""
							}
						)
					}
				}

			}
		}
	}
	let fields = profile.profile.models[model].fields
	let fieldskey = Object.keys(fields);
	console.log(profile);
	//获取联防管理中对应的字符意思
	ack.forEach((item, index) => {
		ack[index].title = fields[item.sensors].title
		ack[index].unit = fields[item.sensors].unit
	})


	// console.log(model + ".alarmSensors=" + ack);
	return ack;
}

//取model中联防数据
const alarmActions = (model) => {
	// console.log("alarmSensors,model=" + model);
	let ack = [];
	let profile = pStore.state.profile;
	//获取联防管理中可设置的参数
	if (model in profile.profile.models) {
		let modelItem = profile.profile.models[model];
		if (modelItem && modelItem.linkage) {
			if ("actions" in modelItem.linkage) {
				let actions = modelItem.linkage["actions"];
				for (let key in actions) {
					if (key != 'comment') {
						ack[key] = true
					}
				}

			}
		}
	}

	console.log("alarmActions=" + ack);
	return ack;
}
export default {
	parseData,
	parseData2,
	parseField,
	parseField2,
	parseValue,
	getParamsData,
	alarmSensors,
	alarmActions
}
